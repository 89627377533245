import {
  ChevronLeftOutlined,
  ChevronRightOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { i18n } from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import EncryptionTool from 'src/modules/encryption/dataEncryption';
import globalSelectors from 'src/modules/global/globalSelectors';
import { getHistory } from 'src/modules/store';
import { headerHeight } from 'src/utils/consts';
import LanguageSelectorItem from 'src/view/shared/form/MuiItems/LanguageSelectorItem';
import NotificationBell from '../notification/NotificationBell';
import CommandPalette from './CommandPalette/CommandPalette';
import SearchBar from './SearchBar';

const Header = (props) => {
  const logoUrl = useSelector(
    authSelectors.selectMenuLogoUrl,
  );

  const [isCommandPaletteOpen, setCommandPaletteOpen] =
    useState(false);

  const handleOpen = () => setCommandPaletteOpen(true);
  const handleClose = () => setCommandPaletteOpen(false);

  const doNavigateToDashboard = () => {
    getHistory().push('/');
  };

  const isEditForm = useSelector(
    globalSelectors.selectIsEditForm,
  );

  const { open, setOpen } = props;
  const location = useLocation();

  useEffect(() => {
    const isEditFormLocal = EncryptionTool.decrypt(
      localStorage.getItem('isEditForm'),
    );
    let passDialog = [
      '/',
      '/will-preview',
      '/subscription',
      '/customers',
      '/performance',
      '/my-account',
    ];

    if (
      (isEditForm === true ||
        isEditFormLocal?.toLowerCase?.() === 'true') &&
      passDialog.indexOf(location.pathname) < 0
    ) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditForm, location]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === 'k') {
        event.preventDefault();
        handleOpen();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#ffffff',
        boxShadow: 'none',
        borderBottom: '1px solid #c1c1c1',
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        height={headerHeight}
        paddingX={2}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <IconButton
            color="default"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            sx={{
              marginRight: 0,
            }}
          >
            {open ? (
              <ChevronLeftOutlined />
            ) : (
              <ChevronRightOutlined />
            )}
          </IconButton>
          <Button
            onClick={doNavigateToDashboard}
            sx={{ marginRight: 2 }}
          >
            <Box
              component="img"
              src={logoUrl}
              sx={{
                // width: '9.06vw',
                height: '3.67vh',
              }}
            />
          </Button>
        </Box>
        <Box display="flex" flex={1} maxWidth={400}>
          <SearchBar onClick={setCommandPaletteOpen} />
          <CommandPalette
            open={isCommandPaletteOpen}
            handleClose={handleClose}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          {!props.isTrustee && (
            <Button
              sx={{
                borderRadius: '24px',
                border: '1px solid rgba(5, 101, 189, 1)',
                width: '7.2vw',
                height: '2.78vh',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(5, 101, 189, 1)',
                },
                padding: '4px',
              }}
              variant="outlined"
              onClick={() => {
                getHistory().push('/subscription');
              }}
            >
              <Typography
                fontFamily="Rubik"
                fontWeight="500"
                fontSize="1.3vh"
                lineHeight="1.58vh"
                color="rgba(5, 101, 189, 1)"
                sx={{
                  '&:hover': {
                    color: 'white',
                  },
                  padding: '0.46vh 1.2vw',
                  borderRadius: '24px',
                }}
              >
                {i18n('dashboard.Subscription')}
              </Typography>
            </Button>
          )}

          <LanguageSelectorItem />

          <Box
            sx={{
              borderLeft: '1px solid rgba(5, 101, 189, 1)',
              paddingLeft: '1.04vw',
            }}
          >
            <NotificationBell />
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
};

export default Header;
