import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { useDispatch, useSelector } from 'react-redux';
import { i18n } from 'src/i18n';
import CustodyCard from 'src/modules/custodyCard/CustodyCard';
import notificationService, {
  PUSH_TYPE,
} from 'src/modules/notification/notificationService';
import Errors from 'src/modules/shared/error/errors';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import actions from 'src/modules/user/list/userListActions';
import selectors from 'src/modules/user/list/userListSelectors';
import userEnumerators from 'src/modules/user/userEnumerators';
import UserService from 'src/modules/user/userService';
import Storage from 'src/security/storage';
import FilesViewer from 'src/view/shared/FilesViewer';
import FilesUploader from 'src/view/shared/uploaders/FilesUploader';
import * as yup from 'yup';

const schema = yup.object().shape({
  trusteeRefNo: yupFormSchemas.string(
    i18n('entities.trustee.client.fields.trusteeRefNo'),
    {
      required: true,
    },
  ),
  status: yupFormSchemas.string(
    i18n('entities.trustee.client.fields.status'),
    {
      required: true,
    },
  ),
  remark: yupFormSchemas.string(
    i18n('entities.trustee.client.fields.remark'),
    {},
  ),
  signedCopy: yupFormSchemas
    .files(
      i18n('entities.trustee.client.fields.signedCopy'),
      {
        max: 1,
      },
    )
    .when('status', {
      is: 'Completed',
      then: yupFormSchemas.files(
        i18n('entities.trustee.client.fields.signedCopy'),
        {
          required: true,
        },
      ),
    }),
  custodyCard: yupFormSchemas
    .files(
      i18n('entities.trustee.client.fields.custodyCard'),
      {
        max: 1,
      },
    )
    .when('status', {
      is: 'Completed',
      then: yupFormSchemas.files(
        i18n('entities.trustee.client.fields.custodyCard'),
        {
          required: false,
        },
      ),
    }),
});

const formatNRIC = (idType, id) => {
  if (idType === 'NRIC' && id?.toString().length === 12) {
    const formattedID = `${id.substring(
      0,
      6,
    )}-${id.substring(6, 8)}-${id.substring(8, 12)}`;

    return formattedID;
  } else {
    return id;
  }
};

const columns = [
  {
    header: i18n(
      'entities.trustee.client.fields.willGeneratedDate',
    ),
    id: 'name',
    align: 'left',
    dataIndex: 'willGeneratedDate',
    render: (item) => {
      const date = moment(item?.willGeneratedDate);

      const gmt8Date = date.utcOffset(8);

      const formattedDate = gmt8Date.format(
        'YYYY-MM-DD hh:mm:ss A',
      );
      return (
        <>
          <Typography fontSize="12px">
            {formattedDate}
          </Typography>
        </>
      );
    },
  },
  {
    header: i18n(
      'entities.trustee.client.fields.willReferenceNo',
    ),
    id: 'willReferenceNo',
    align: 'left',
    render: (item) => {
      return (
        <>
          <Typography fontSize="12px">
            {item?.willReferenceNo}
          </Typography>
        </>
      );
    },
  },
  {
    header: i18n(
      'entities.trustee.client.fields.trusteeRefNo',
    ),
    id: 'refNo',
    align: 'left',
    dataIndex: 'refNo',
    render: (item) => {
      return (
        <>
          <Typography fontSize="12px">
            {item.refNo}
          </Typography>
        </>
      );
    },
  },
  {
    header: i18n('entities.trustee.client.fields.status'),
    id: 'status',
    align: 'left',
    dataIndex: 'status',
    render: (item) => {
      return (
        <>
          <Typography fontSize="12px">
            {item.status}
          </Typography>
        </>
      );
    },
  },
  {
    header: i18n(
      'entities.trustee.client.fields.signedCopy',
    ),
    id: 'signedCopy',
    align: 'left',
    dataIndex: 'signedCopy',
    width: '100px',
    render: (item) => {
      return (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <div style={{ maxWidth: '65px' }}>
              <FilesViewer
                value={item.signedCopy}
                hideName={true}
              />
            </div>
            {item.signedCopy &&
              item.signedCopy.length > 0 && (
                <Tooltip title={item.signedCopy[0].name}>
                  <InfoOutlinedIcon />
                </Tooltip>
              )}
          </div>
        </>
      );
    },
  },
  {
    header: i18n(
      'entities.trustee.client.fields.custodyCard',
    ),
    id: 'custodyCard',
    align: 'left',
    dataIndex: 'custodyCard',
    width: '100px',
    render: (item) => {
      return (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <div style={{ maxWidth: '65px' }}>
              <FilesViewer
                value={item.custodyCard}
                hideName={true}
              />
            </div>
            {item.custodyCard &&
              item.custodyCard.length > 0 && (
                <Tooltip title={item.custodyCard[0].name}>
                  <InfoOutlinedIcon />
                </Tooltip>
              )}
          </div>
        </>
      );
    },
  },
];

const TrusteeClientForm = (props) => {
  const dispatch = useDispatch();
  const rawFilter = useSelector(selectors.selectRawFilter);

  const onSubmit = async (values, { setSubmitting }) => {
    props.setIsLoading(true);

    try {
      const submitData = {
        refNo: values.trusteeRefNo,
        status: values.status,
        signedCopy: values.signedCopy,
        custodyCard: values.custodyCard,
        remark: values.remark,
      };

      var request = {
        client_user_id:
          props?.trusteeClientRow?.remote_user_id,
        update_data: submitData,
      };

      await UserService.Update_Trustee_Client(request);

      if (values.status === 'Document Received') {
        notificationService.Push_Notification(
          i18n(
            'entities.trustee.notification.documentReceived',
          ),
          '',
          true,
          PUSH_TYPE.TESTATOR,
          props?.trusteeClientRow?.remote_user_id,
        );
      } else if (values.status === 'Completed') {
        notificationService.Push_Notification(
          i18n('entities.trustee.notification.completed'),
          '',
          true,
          PUSH_TYPE.TESTATOR,
          props?.trusteeClientRow?.remote_user_id,
        );
      }

      dispatch(
        actions.doFetchTrusteeClientFilter(
          null,
          rawFilter,
          true,
        ),
      );
      props.onClose();

      //   props.doUpdateTask(record);
    } catch (error) {
      Errors.handle(error);
    } finally {
      // setSaveLoading(false);
    }

    setSubmitting(false);
    props.setIsLoading(false);
  };

  const renderProductTag = (item) => {
    const tags: React.ReactElement[] = [];

    // Add similar checks for other product fields like Paid_Advisory, etc.

    if (item?.Paid_Executor_Palladium) {
      tags.push(
        <span
          style={{
            border: 'solid 1px #E6EFF8',
            borderRadius: '10px',
            padding: '4px',
            background: '#E6EFF8',
            fontSize: '12px',
            color: '#2176C4',
            marginRight: '4px',
          }}
        >
          {i18n('user.fields.corporateTrustee')}
        </span>,
      );
    }

    if (item?.Paid_Custodian_Palladium) {
      tags.push(
        <span
          style={{
            border: 'solid 1px #E6EFF8',
            borderRadius: '10px',
            padding: '4px',
            background: '#E6EFF8',
            fontSize: '12px',
            color: '#2176C4',
            marginRight: '4px',
          }}
        >
          {i18n('user.fields.custodianPalladium')}
        </span>,
      );
    }

    if (item?.Paid_Testamentary_Palladium) {
      tags.push(
        <span
          style={{
            border: 'solid 1px #E6EFF8',
            borderRadius: '10px',
            padding: '4px',
            background: '#E6EFF8',
            fontSize: '12px',
            color: '#2176C4',
            marginRight: '4px',
          }}
        >
          {i18n('user.fields.testamentaryPalladium')}
        </span>,
      );
    }

    return tags.length > 0 ? (
      <>
        {tags.map((tag) => (
          <>
            <span key={tag.key}>{tag}</span>
            <br />
          </>
        ))}
      </>
    ) : null;
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <Typography
            fontFamily="Rubik"
            fontWeight="500"
            fontSize="2.96vh"
            lineHeight="3.52vh"
            color="#222F4F"
          >
            {props?.trusteeClientRow?.fullName}
          </Typography>
          <Typography
            fontFamily="Rubik"
            fontWeight="400"
            fontSize="1.3vh"
            lineHeight="1.57vh"
            color="rgba(113, 129, 146, 1)"
          >
            {i18n(
              'entities.trustee.client.fields.lastActivity',
            ) +
              ' ' +
              moment(
                props?.trusteeClientRow?.lastLoginTime,
              ).format('DD MMM YYYY')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {renderProductTag(props?.trusteeClientRow)}
        </Box>
      </Box>
      <br />
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          trusteeRefNo:
            props?.trusteeClientRow?.trustee?.refNo ?? '',
          status:
            props?.trusteeClientRow?.trustee?.status ?? '',
          signedCopy:
            props?.trusteeClientRow?.trustee?.signedCopy ??
            null,
          custodyCard:
            props?.trusteeClientRow?.trustee?.custodyCard ??
            null,
          remark:
            props?.trusteeClientRow?.trustee?.remark ??
            null,
        }}
        validationSchema={schema}
        validateOnMount={true}
      >
        {({
          errors,
          touched,
          setFieldValue,
          isValid,
          handleChange,
          values,
        }) => (
          <Form>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <TextField
                  label={i18n(
                    'entities.trustee.client.fields.gender',
                  )}
                  fullWidth
                  value={
                    props?.trusteeClientRow?.gender ===
                    'male'
                      ? i18n(
                          'entities.trustee.client.fields.male',
                        )
                      : i18n(
                          'entities.trustee.client.fields.female',
                        )
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={i18n(
                    'entities.trustee.client.fields.idNo',
                  )}
                  fullWidth
                  value={formatNRIC(
                    props?.trusteeClientRow?.idType,
                    props?.trusteeClientRow?.idNo,
                  )}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={i18n(
                    'entities.trustee.client.fields.dob',
                  )}
                  fullWidth
                  value={moment(
                    props?.trusteeClientRow?.dob,
                  ).format('DD MMM YYYY')}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={i18n(
                    'entities.trustee.client.fields.phoneNumberCountryCode',
                  )}
                  fullWidth
                  value={
                    en[
                      props?.trusteeClientRow
                        ?.phoneNumberCountryCode
                    ] +
                      ' +' +
                      getCountryCallingCode(
                        props?.trusteeClientRow
                          ?.phoneNumberCountryCode,
                      ) || undefined
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label={i18n(
                    'entities.trustee.client.fields.phoneNumber',
                  )}
                  fullWidth
                  value={
                    props?.trusteeClientRow?.phoneNumber
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={i18n(
                    'entities.trustee.client.fields.willReferenceNo',
                  )}
                  fullWidth
                  value={
                    props?.trusteeClientRow?.trustee
                      ?.willReferenceNo
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={i18n(
                    'entities.trustee.client.fields.trusteeRefNo',
                  )}
                  fullWidth
                  name="trusteeRefNo"
                  value={values.trusteeRefNo}
                  error={
                    touched.trusteeRefNo &&
                    Boolean(errors.trusteeRefNo)
                  }
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="statusSelectLabel">
                    {i18n(
                      'entities.trustee.client.fields.status',
                    )}
                  </InputLabel>
                  <Select
                    fullWidth
                    name="status"
                    labelId="statusSelectLabel"
                    id="statusSelect"
                    value={values.status}
                    label={i18n(
                      'entities.trustee.client.fields.status',
                    )}
                    onChange={handleChange}
                    error={
                      touched.status &&
                      Boolean(errors.status)
                    }
                    disabled={
                      values.status === 'Subscribed'
                    }
                  >
                    {userEnumerators.trusteeClientStatus.map(
                      (item, index) => {
                        if (item !== 'All') {
                          return (
                            <MenuItem
                              value={item}
                              key={`status_${values.status}_${index}`}
                              disabled={
                                index === 1 || index === 2
                              }
                            >
                              {i18n(
                                `user.enumerators.trusteeClientStatus.${item}`,
                              )}
                            </MenuItem>
                          );
                        }
                        return null;
                      },
                    )}
                  </Select>
                  {values.status === 'Subscribed' && (
                    <Typography
                      fontFamily="Rubik"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="18px"
                      color="red"
                    >
                      {i18n(
                        'entities.trustee.client.fields.generateAWill',
                      )}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={i18n(
                    'entities.trustee.client.fields.remarks',
                  )}
                  fullWidth
                  name="remark"
                  value={values.remark}
                  error={
                    touched.remark && Boolean(errors.remark)
                  }
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <FormControl>
              <Grid
                container
                spacing={2}
                minWidth={'24vw'}
                maxWidth={'24vw'}
                alignItems={'center'}
                marginTop="2%"
              >
                <Grid item xs={4}>
                  <Typography
                    fontFamily="Rubik"
                    fontWeight="500"
                    fontSize="1.85vh"
                    lineHeight="2.22vh"
                  >
                    {i18n(
                      'entities.trustee.client.fields.signedCopy',
                    )}
                    {values.status === 'Completed' && (
                      <span style={{ color: 'red' }}>
                        {' '}
                        *
                      </span>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FilesUploader
                    storage={Storage.values.Signed_Copy}
                    formats={undefined}
                    value={values.signedCopy}
                    onChange={(value) => {
                      setFieldValue(
                        'signedCopy',
                        value,
                        true,
                      );
                    }}
                    max={1}
                  />
                </Grid>
                {errors.signedCopy &&
                  touched.signedCopy && (
                    <Grid item xs={12}>
                      <Typography
                        fontFamily="Rubik"
                        fontWeight="400"
                        fontSize="1.55vh"
                        lineHeight="2.22vh"
                        color="red"
                      >
                        {errors.signedCopy}
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            </FormControl>
            <Typography
              fontFamily="Rubik"
              fontWeight="500"
              fontSize="1.85vh"
              lineHeight="2.22vh"
              mt={5}
              mb={2}
            >
              {i18n(
                'entities.trustee.client.fields.custodyCard',
              )}
              {/* TODO: fetch idNumber & serialCode */}
            </Typography>
            <CustodyCard
              numCopies={1}
              idNumber={formatNRIC(
                props?.trusteeClientRow?.idType,
                props?.trusteeClientRow?.idNo,
              )}
              serialCode={props?.trusteeClientRow?.remote_user_id?.toUpperCase()}
            />

            {props?.trusteeClientRow?.trustee?.history
              ?.length > 0 && (
              <Box sx={{ marginTop: '24px' }}>
                <Typography
                  fontFamily="Rubik"
                  fontWeight="500"
                  fontSize="1.85vh"
                  lineHeight="2.22vh"
                >
                  {i18n(
                    'entities.trustee.client.fields.history',
                  )}
                </Typography>
                <TableContainer
                  style={{
                    backgroundColor: 'white',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    border: '1px solid #d9d9d9',
                    marginTop: '16px',
                  }}
                >
                  <Table
                    id="manage-client"
                    key="manage-client-table"
                    aria-label="user table"
                    stickyHeader
                    sx={{
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                      backgroundColor: 'white',
                    }}
                  >
                    <TableHead
                      sx={{
                        background: '',
                        color: '',
                        width: '100px',
                        '& th:first-child': {
                          borderTopLeftRadius: '10px',
                        },
                        '& th:last-child': {
                          borderTopRightRadius: '10px',
                        },
                      }}
                    >
                      <TableRow>
                        {columns.map((column: any) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              backgroundColor: 'white',
                              color: 'grey',
                              fontWeight: '400',
                              paddingTop: '10px',
                              paddingBottom: '10px',
                              fontSize: '12px',
                            }}
                            size={'small'}
                          >
                            {column.header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props?.trusteeClientRow?.trustee?.history?.map(
                        (row) => (
                          <TableRow
                            key={row._id}
                            sx={{
                              backgroundColor: '#eef7ff',
                            }}
                          >
                            {columns.map((column: any) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                sx={{
                                  maxWidth: 230,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  paddingTop: '5px',
                                  paddingBottom: '5px',
                                }}
                              >
                                {column.render(row)}
                              </TableCell>
                            ))}
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}

            {/* button component at below */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  marginTop: '3.7vh',
                  width: '20.6vw',
                  height: '5.55vh',
                  textTransform: 'none',
                  borderRadius: '64px',
                  background:
                    'linear-gradient(270deg, #0078FF 0%, #004A9E 100%)',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                }}
                type="submit"
              >
                <Typography
                  fontFamily="Rubik"
                  fontWeight="500"
                  fontSize="1.85vh"
                  lineHeight="2.22vh"
                  color="#FFFFFF"
                >
                  {props.edit
                    ? i18n('common.save')
                    : i18n('common.confirm')}
                </Typography>
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TrusteeClientForm;
